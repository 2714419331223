<template>
  <v-container class="font-Roboto bg-color-theme bussiness-day-main">
    <v-card-text class="white elevation-2 rounded-5 py-5">
      <h6 class="font-14px text-85 mb-6">最終更新日：{{ lastUpdateDate }}</h6>
      <div class="input-select-box mx-auto">
        <v-select
          dense
          solo
          :items="getYears"
          class="font-15px mb-2"
          background-color="#EAEDF0"
          item-text="text"
          item-value="value"
          hide-details
          v-model="year"
          @change="getDataFromApi()"
        ></v-select>
      </div>
      <div class="input-select-box mx-auto">
        <v-select
          dense
          solo
          :items="getMonths"
          item-text="text"
          item-value="value"
          class="font-15px mb-2"
          background-color="#EAEDF0"
          hide-details
          v-model="month"
          @change="getDataFromApi()"
        ></v-select>
      </div>
      <div class="border-bottom-slider mx-n4 mt-3"></div>
      <div class="d-flex justify-center bussiness-days-blk mt-3">
        <div class="light-yellow">
          <span class="font-14px greenish--text">
            営業日
          </span>
          <h6 class="font-18px mb-0 ml-2">
            {{ noOfBuisnessDay }}
            <span class="font-14px">日</span>
          </h6>
        </div>
        <div class="light-yellow ml-3">
          <span class="font-14px greenish--text">
            店休日
          </span>
          <h6 class="font-18px mb-0 ml-2">
            {{ noOfRestDay }}
            <span class="font-14px">日</span>
          </h6>
        </div>
      </div>
      <div class="text-right mt-1 mb-2">
        <v-btn
          text
          class="pa-1"
          @click="toggleSection = !toggleSection"
          height="auto"
          :ripple="false"
          v-if="isShow"
        >
          <span class="mr-2">一括設定</span>
          <v-icon class="circle-checked" size="10" color="#000"
            >$arrowDown</v-icon
          >
        </v-btn>
      </div>
      <v-btn-toggle v-if="toggleSection" class="store-top-sec" v-model="topBtn" tile group st>
        <div class="store-holdays-blk top-sec">
          <h6 class="mb-5 font-Roboto font-12px fw-400 text-center text-333">
            店休日を選択してください。
          </h6>
          <v-btn
            block
            small
            text
            value="btn1"
            active-class="active"
            retain-focus-on-click
            class="holidays-btn fw-400 font-12px mb-2"
            @click="changeBulkDaysStatus(1)"
          >
            全てを営業日にする
          </v-btn>
          <v-btn
            block
            small
            text
            value="btn2"
            active-class="active"
            retain-focus-on-click
            class="holidays-btn fw-400 font-12px mb-2"
            @click="changeBulkDaysStatus(2)"
          >
            全てを店休日にする
          </v-btn>
        </div>
      </v-btn-toggle>
      <v-btn-toggle v-if="toggleSection" v-model="toggle_exclusive" multiple tile group>
        <div class="store-holdays-blk">
          <div class="d-flex align-center flex-btns mb-2">
            <v-btn
              text
              small
              value="月"
              active-class="active"
              retain-focus-on-click
              class="holidays-btn fw-400 font-12px left-btn"
              @click="changeDaysStatusByName('月')"
            >
              月曜を店休日にする
            </v-btn>
            <v-btn
              text
              small
              value="火"
              active-class="active"
              retain-focus-on-click
              class="holidays-btn fw-400 font-12px"
              @click="changeDaysStatusByName('火')"
            >
              火曜を店休日にする
            </v-btn>
          </div>
          <div class="d-flex align-center flex-btns mb-2">
            <v-btn
              text
              small
              value="水"
              active-class="active"
              retain-focus-on-click
              class="holidays-btn fw-400 font-12px left-btn"
              @click="changeDaysStatusByName('水')"
            >
              水曜を店休日にする
            </v-btn>
            <v-btn
              text
              small
              value="木"
              active-class="active"
              retain-focus-on-click
              class="holidays-btn fw-400 font-12px"
              @click="changeDaysStatusByName('木')"
            >
              木曜を店休日にする
            </v-btn>
          </div>
          <div class="d-flex align-center flex-btns mb-2">
            <v-btn
              text
              small
              value="金"
              active-class="active"
              retain-focus-on-click
              class="holidays-btn fw-400 font-12px left-btn"
              @click="changeDaysStatusByName('金')"
            >
              金曜を店休日にする
            </v-btn>
            <v-btn
              text
              small
              value="土"
              active-class="active"
              retain-focus-on-click
              class="holidays-btn fw-400 font-12px"
              @click="changeDaysStatusByName('土')"
            >
              土曜を店休日にする
            </v-btn>
          </div>
          <div class="d-flex align-center flex-btns mb-2">
            <v-btn
              text
              small
              value="日"
              active-class="active"
              retain-focus-on-click
              class="holidays-btn fw-400 font-12px left-btn"
              @click="changeDaysStatusByName('日')"
            >
              日曜を店休日にする
            </v-btn>
          </div>
        </div>
      </v-btn-toggle>
      <div class="business-days-table mt-5">
        <v-simple-table>
          <tbody>
            <tr
              v-for="(item, i) in days"
              :key="item.value"
              :class="item.status == 2 ? 'red-class' : ''"
            >
              <td>
                {{ item.text }}
              </td>
              <td>
                <v-btn
                  active-class="text-heading-3"
                  x-small
                  text
                  @click="changeDayStatus(i, item.status)"
                  plain
                  :ripple="false"
                  :loading="loading"
                  class="font-12px fw-400 status-btn"
                  :class="item.status == 2 ? 'red-class' : ''"
                  :disabled="disabled"
                >
                  <span v-if="item.status == 2" class="red-text">店休日</span>
                  <span v-if="item.status == 1">営業日</span>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </div>
      <div class="mt-7 d-flex justify-space-between align-center">
        <v-btn
          min-width="146px"
          color="white border-primary primary--text font-14px"
          @click="cancel()"
        >
          キャンセル
        </v-btn>
        <v-btn
          type="button"
          min-width="146px"
          :loading="loading"
          color="primary white--text font-14px"
          @click="submit()"
        >
          保存する
        </v-btn>
      </div>
    </v-card-text>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import dayjs from "@/plugins/dayjs";
export default {
  name: "BusinessDaySetting",
  data() {
    return {
      seen: false,
      loading: false,
      lastUpdateDate: null,
      noOfBuisnessDay: 0,
      noOfRestDay: 0,
      year: "",
      month: "",
      active: null,
      dayStatus: "1",
      topBtn: "",
      toggleSection: false,
      disabled: false,
      isShow:true,
      days: [],
      toggle_exclusive: []
    };
  },
  computed: {
    ...mapGetters(["getBuisnessByYear"]),
    getYears() {
      var now = new Date();
      let yearsData = [];
      for (let i = -1; i <= 1; i++) {
        var years = now.getFullYear() - i;
        yearsData.push({ value: years, text: years + "年" });
      }
      return yearsData;
    },
    getMonths() {
      var now = new Date();
      let currMonth = now.getMonth() + 1;
      var currYear = now.getFullYear();
      let months = [];
      if (this.year < currYear) {
        for (let i = currMonth; i <= 12; i++) {
          months.push({ value: i, text: i + "月" });
        }
      }

      if (this.year > currYear) {
        for (let i = 1; i <= currMonth; i++) {
          months.push({ value: i, text: i + "月" });
        }
      }

      if (this.year == currYear) {
        for (let i = 1; i <= 12; i++) {
          months.push({ value: i, text: i + "月" });
        }
      }

      return months;
    }
  },
  created() {
    var now = new Date();
    this.lastUpdateDate = dayjs().format("YYYY-MM-DD");
    this.year = now.getFullYear();
    this.month = now.getMonth() + 1;
    this.getDays();

    let this_this = this;
    this.$root.$off("shopChanged");
    this.$root.$on("shopChanged", function() {
      this_this.getDataFromApi();
    });
    this.getDataFromApi();
  },
  methods: {
    getDays() {
      this.days = [];
      let daysInMonth = new Date(this.year, this.month, 0).getDate();
      for (let i = 1; i <= daysInMonth; i++) {
        let dayName = dayjs(this.year + "-" + this.month + "-" + i)
          .locale("ja")
          .format("ddd");
        this.days.push({
          value: i,
          text: this.month + "/" + i + " " + dayName,
          day: dayName,
          status: 1
        });
      }
      this.lastUpdateDate = dayjs().format("YYYY-MM-DD");
      this.noOfBuisnessDay = daysInMonth;
      this.noOfRestDay = 0;
      return this.days;
    },
    changeDayStatus(i, dayStatus) {
      let item = this.days[i];

      let status = "";
      if (item.status === 1) {
        status = 2;
        this.noOfRestDay += 1;
        this.noOfBuisnessDay -= 1;
      } else {
        status = 1;
        this.noOfRestDay -= 1;
        this.noOfBuisnessDay += 1;
      }

      this.$set(this.days, i, {
        value: item.value,
        text: item.text,
        day: item.day,
        status: status
      });
    },
    changeBulkDaysStatus(status) {
      this.toggle_exclusive.splice(0,this.toggle_exclusive.length);
      let item = this.days;
      let buisnessDays = [];
      let restDays = [];
      for (let i = 0; i < item.length; i++) {
        this.$set(this.days, i, {
          value: item[i].value,
          text: item[i].text,
          day: item[i].day,
          status: status
        });

        if(status == 2){
          if(this.toggle_exclusive.length != 7){
              this.toggle_exclusive.push(item[i].day);
          }
        }else{
          this.toggle_exclusive.splice(0,this.toggle_exclusive.length);
        }

        if (status == 1) {
          buisnessDays.push(status);
        } else {
          restDays.push(status);
        }
      }
      this.noOfBuisnessDay = buisnessDays.length;
      this.noOfRestDay = restDays.length;
    },
    changeDaysStatusByName(dayname) {
      let item = this.days;
      for (let i = 0; i < item.length; i++) {
        if (item[i].status == 1) {
          if(dayname == item[i].day){
            this.dayStatus = 2;
            this.$set(this.days, i, {
              value: item[i].value,
              text: item[i].text,
              day: item[i].day,
              status: 2
            });
            if (item[i].status == 2 && this.noOfRestDay < item.length) {
              this.noOfRestDay += 1;
              this.noOfBuisnessDay -= 1;
            }
          }
        } else {
          if(dayname == item[i].day){
            this.topBtn = null;

            this.dayStatus = 1;
            this.$set(this.days, i, {
              value: item[i].value,
              text: item[i].text,
              day: item[i].day,
              status: 1
            });
            if (item[i].status == 1 && this.noOfRestDay <= item.length) {
              this.noOfRestDay -= 1;
              this.noOfBuisnessDay += 1;
            }
          }
        }
      }
    },
    submit() {
      this.loading = true;
      let params = [];
      let shop_id = this.$store.getters.getFirstShopObject.id;
      for (let i = 0; i < this.days.length; i++) {
        params.push({
          shop_id: shop_id,
          date: this.year + "-" + this.month + "-" + this.days[i]["value"],
          day_name: this.days[i]["day"],
          regular_holiday_or_business_day: this.days[i]["status"]
        });
      }

      if (this.getBuisnessByYear.length > 0) {
        let business_id = 1;
        this.$store
          .dispatch("BUSINESS_UPDATE", { params, shop_id, business_id })
          .then(
            response => {
              if (response.status === 200) {
                this.getDataFromApi();
                this.$store.commit("showAlert", {
                  text: "編集内容を保存しました。",
                  successStatus: "info"
                });
                this.loading = false;
              }
            },
            error => {
              throw error;
            }
          );
      } else {
        params["year"] = this.year;
        this.$store.dispatch("BUSINESS_REGISTER", { params, shop_id }).then(
          response => {
            if (response.status === 200) {
              this.getDataFromApi();
              this.$store.commit("showAlert", {
                text: "編集内容を保存しました。",
                successStatus: "info"
              });
              this.loading = false;
            }
          },
          error => {
            throw error;
          }
        );
      }
    },
    getDataFromApi() {
      this.loading = true;
      let data = {
        shop_id: this.$store.getters.getFirstShopObject.id, //Shop Id
        year: this.year,
        month: this.month
      };

      this.$store.dispatch("BUSINESS_GET_BY_YEAR", data).finally(() => {
        this.loading = false;
        this.patchEditFormValues();
      });
    },
    patchEditFormValues() {
      this.toggle_exclusive.splice(0,this.toggle_exclusive.length);
      let today = dayjs();
      let previousYear = today.subtract(1, "year").format("YYYY");
      let nextYearMonth = today.add(1, "year").format("YYYY")+"-"+today.add(1, "year").format("M");

      if(this.year == previousYear || nextYearMonth == this.year+"-"+this.month){
        this.disabled = true;
        this.isShow = false;
        this.toggleSection = false;
      }else{
         this.disabled = false;
         this.isShow = true;
         this.toggleSection = false;
      }

      this.days = [];
      let buisnessDays = [];
      let restDays = [];
      if (this.getBuisnessByYear.length > 0) {
        for (let i = 0; i < this.getBuisnessByYear.length; i++) {
          this.lastUpdateDate = dayjs(
            this.getBuisnessByYear[i].updated_at
          ).format("YYYY-MM-DD");
          var buisnessDate = this.getBuisnessByYear[i].date.split("-");
          let buisnessMonth = buisnessDate[1];
          let buisnessDay = buisnessDate[2];

          let dayName = new Date(
            this.getBuisnessByYear[i].date
          ).toLocaleString("ja-JP", { weekday: "short" });

          if(this.getBuisnessByYear[i].regular_holiday_or_business_day == 2 && this.toggle_exclusive.length != 7){
            this.toggle_exclusive.push(dayName);
          }

          this.days.push({
            value: buisnessDay,
            text: buisnessMonth + "/" + buisnessDay + " " + dayName,
            day: dayName,
            status: this.getBuisnessByYear[i].regular_holiday_or_business_day
          });
          if (this.getBuisnessByYear[i].regular_holiday_or_business_day == 1) {
            buisnessDays.push(
              this.getBuisnessByYear[i].regular_holiday_or_business_day
            );
          } else {
            restDays.push(
              this.getBuisnessByYear[i].regular_holiday_or_business_day
            );
          }
        }
        this.noOfBuisnessDay = buisnessDays.length;
        this.noOfRestDay = restDays.length;

        return this.days;
      } else {
        this.getDays();
      }
    },
    cancel() {
      return this.$router.go();
    }
  }
};
</script>

<style lang="scss" src="./style.scss" scope></style>
